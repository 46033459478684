import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', image, description, ...other }, ref) => (
    <Box ref={ref} {...other}>
        <Helmet>
            <title>{title}</title>
            <meta property='og:image' content={image} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content="Make your lunch order here" />
        </Helmet>
        {children}
    </Box>
));


export default Page;
