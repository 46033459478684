import { CalendarToday, DeliveryDining, Home, HourglassEmpty, Link, Person, Phone, Timer } from '@mui/icons-material';
import { Avatar, Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function CreateOrderConfirm(props) {
    const { information, isMobile } = props;

    const addZero = (num) => {
        if (num < 10) {
            return "0" + num;
        }
        return num;
    }

    const formatDate = (date) => {
        let d = new Date(date);
        return addZero(d.getDate()) + "/" + addZero(d.getMonth() + 1) + "/" + d.getFullYear();
    }

    const formatTime = (time) => {
        let t = new Date(time);
        return addZero(t.getHours()) + ":" + addZero(t.getMinutes())
    }

    if (isMobile) {
        return <Box sx={{
            textAlign: 'center'
        }}>
            <Card sx={{
                borderRadius: '10px',
                position: 'relative',
                p: 2
            }}>
                <Typography variant="h5">Bekræft oplysninger</Typography>
                <Typography variant="body2" color="text.secondary">Indsæt url til menuen og tilpas derefter navnet</Typography>
            </Card>
            <Box sx={{
                mx: 2
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <Link></Link>
                    </Avatar>
                    <Typography color="text.secondary">{information.url}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <Home></Home>
                    </Avatar>
                    <Typography color="text.secondary">{information.title}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <CalendarToday></CalendarToday>
                    </Avatar>
                    <Typography color="text.secondary">{formatDate(information.date)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <Timer></Timer>
                    </Avatar>
                    <Typography color="text.secondary">{formatTime(information.eatTime)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <HourglassEmpty></HourglassEmpty>
                    </Avatar>
                    <Typography color="text.secondary">{formatTime(information.orderTime)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <Person></Person>
                    </Avatar>
                    <Typography color="text.secondary">{information.info.interpreter}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <Phone></Phone>
                    </Avatar>
                    <Typography color="text.secondary">{information.info.phone}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1
                }}>
                    <Avatar sx={{
                        bgcolor: 'primary.main',
                        mr: 2
                    }}>
                        <DeliveryDining />
                    </Avatar>
                    <Typography color="text.secondary">{`${information.info.delivery},- DKK`}</Typography>
                </Box>
            </Box>
        </Box>
    }

    return <Box sx={{
        textAlign: 'center'
    }}>
        <Typography variant="h5">Bekræft oplysninger</Typography>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <Link></Link>
            </Avatar>
            <Typography color="text.secondary">{information.url}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <Home></Home>
            </Avatar>
            <Typography color="text.secondary">{information.title}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <CalendarToday></CalendarToday>
            </Avatar>
            <Typography color="text.secondary">{formatDate(information.date)}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <Timer></Timer>
            </Avatar>
            <Typography color="text.secondary">{formatTime(information.eatTime)}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <HourglassEmpty></HourglassEmpty>
            </Avatar>
            <Typography color="text.secondary">{formatTime(information.orderTime)}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <Person></Person>
            </Avatar>
            <Typography color="text.secondary">{information.info.interpreter}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <Phone></Phone>
            </Avatar>
            <Typography color="text.secondary">{information.info.phone}</Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            my: 1
        }}>
            <Avatar sx={{
                bgcolor: 'primary.main',
                mr: 2
            }}>
                <DeliveryDining />
            </Avatar>
            <Typography color="text.secondary">{`${information.info.delivery},- DKK`}</Typography>
        </Box>
    </Box>
}