import jwtDecode from 'jwt-decode';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.user;
    }
    case 'clear':
      window.localStorage.removeItem('token');
      return { ...state, user: null };
    default:
      return state;
  }
};

let initState;
try {
  initState = jwtDecode(window.localStorage.getItem('token'))
} catch (error) {
  initState = {}
}
export const initialState = initState;
