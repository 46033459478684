import { Box, Card, TextField, Typography } from "@mui/material";
import React from "react";

export default function CreateOrderMeta(props) {
    const { getMetaInfo, title, setTitle, url, setUrl, isMobile } = props;

    if (isMobile) {
        return <Box sx={{
            textAlign: 'center'
        }}>
            <Card sx={{
                borderRadius: '10px',
                position: 'relative',
                p: 2
            }}>
                <Typography variant="h5">Vælg sted</Typography>
                <Typography variant="body2" color="text.secondary">Indsæt url til menuen og tilpas derefter navnet</Typography>
            </Card>
            <Box sx={{
                my: 3,
                mx: 2,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column'
            }}>
                <TextField
                    margin="dense"
                    id="title"
                    label="Url"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="title"
                    sx={{
                        width: '100%',
                        maxWidth: '500px',
                        margin: 'auto'
                    }}
                    onBlur={(value) => getMetaInfo(value)}
                    onChange={(value) => setUrl(value.target.value)}
                    value={url}
                    placeholder={"Link til menu"}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Titel"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="title"
                    sx={{
                        width: '100%',
                        maxWidth: '500px',
                        margin: 'auto',
                        my: 3,
                    }}
                    value={title}
                    onChange={(value) => setTitle(value.target.value)}
                    defaultValue="Navn på sted"
                />
            </Box>
        </Box>
    }

    return <Box sx={{
        textAlign: 'center'
    }}>
        <Typography variant="h5">Vælg sted</Typography>
        <Box sx={{
            my: 3,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column'
        }}>
            <TextField
                margin="dense"
                id="title"
                label="Url"
                type="text"
                fullWidth
                variant="outlined"
                name="title"
                sx={{
                    width: '100%',
                    maxWidth: '500px',
                    margin: 'auto'
                }}
                onBlur={(value) => getMetaInfo(value)}
                onChange={(value) => setUrl(value.target.value)}
                value={url}
                placeholder={"Link til menu"}
            />

            <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Titel"
                type="text"
                fullWidth
                variant="outlined"
                name="title"
                sx={{
                    width: '100%',
                    maxWidth: '500px',
                    margin: 'auto',
                    my: 3,
                }}
                value={title}
                onChange={(value) => setTitle(value.target.value)}
                defaultValue="Navn på sted"
            />
        </Box>
    </Box>
}