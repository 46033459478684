import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import Page from '../components/page';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://individualisterne.dk/">
                Individualisterne
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function CreateUser(props) {
    // const { setUser } = props;
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch("https://api.individualisterne.dk/api/create-user", {
            method: 'POST',
            body: JSON.stringify({
                "email": data.get('email'),
                "password": data.get('password'),
                "fname": data.get('firstname'),
                "lname": data.get('lastname'),
                "phone": data.get('phone'),
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json().then(json => {
            if (response.ok) {
                window.localStorage.setItem("token", json.token)
                // window.localStorage.setItem("user", JSON.stringify(json))
                // setUser(json)
                navigate("/")
            } else {
                setErrorMessage("Forkert email eller adgangskode")
                setError(true)
            }
        })).catch(e => {
            setErrorMessage("Forkert email eller adgangskode")
            setError(true)
        })
    };

    return (
        <Page title="Opret bruger | Individualisterne">

            <Box sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ textAlign: 'start', width: '100%' }}>
                            <Button startIcon={<ArrowBack></ArrowBack>} onClick={() => navigate("/login")}>Tilbage</Button>
                        </Box>
                        <img alt="logo" src="images/logostu.png" style={{ width: '100%' }}></img>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstname"
                                label="Fornavn"
                                name="firstname"
                                autoComplete="firstname"
                                autoFocus
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastname"
                                label="Efternavn"
                                name="lastname"
                                autoComplete="lastname"
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="phone"
                                label="Telefon"
                                name="phone"
                                autoComplete="phone"
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Adgangskode"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirm-password"
                                label="Bekræft adgangskode"
                                type="password"
                                id="confirm-password"
                                autoComplete="current-password"
                                error={error}
                                helperText={error ? errorMessage : null}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Opret
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </Box>
        </Page>
    );
}