import { Add, Clear } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

export default function OrderDialog(props) {
    const { open, onClose, item, order_id, handleSubmit, handleEdit, user } = props;
    const [products, setProducts] = useState([{ title: "", price: 0 }])
    const [description, setDescription] = useState("")

    useEffect(() => {
        if (item !== undefined) {
            if (item.orderitems !== undefined) {
                setProducts(JSON.parse(item.orderitems))
                setDescription(item.description)
            } else {
                setProducts([{}])
                setDescription("")
            }
        }
    }, [item])

    const validateAndSubmit = () => {
        let temp = [];
        let error = false;
        let errorMessage = "";
        products.forEach(element => {
            if (element.title !== "" && element.title !== undefined) {
                if (element.price === undefined) {
                    element.price = 0;
                }
                temp.push(element)
            } else if ((element.title === "" || element.title === undefined) && element.price > 0) {
                temp.push(element)
                error = true;
                errorMessage = "Produkt navn kan ikke være tomt"
                element.error = errorMessage
            } else if (element.title !== undefined && element.price !== undefined) {
                error = true;
                errorMessage = "Der er sket en ukendt fejl"
                element.error = errorMessage
            }
        })
        setProducts(temp)
        if (!error && temp.length > 0) {
            handleSubmit(JSON.stringify({
                order_id: parseInt(order_id),
                user_id: user.id,
                description: item.description,
                orderitems: JSON.stringify(products)
            }))
        } else if (!error) {
            onClose();
        } else {
            console.log(errorMessage)
        }
    }

    const removeFields = (index) => {
        let newFields = [...products];
        newFields.splice(index, 1);
        setProducts(newFields);
    }

    const handleChange = (index, e) => {
        let newValues = [...products];
        newValues[index][e.target.name] = e.target.value;
        setProducts(newValues);
    }


    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Tilføj/Rediger ordre</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Tilføj produkt og pris
            </DialogContentText>
            {
                products.map((item, index) => (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }} key={index}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Titel"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="title"
                            sx={{
                                width: '200px',
                                mr: 1
                            }}
                            onChange={(value) => handleChange(index, value)}
                            value={item.title}
                            error={item.error !== undefined}
                        />
                        <TextField
                            margin="dense"
                            id="price"
                            label="Pris"
                            type="number"
                            fullWidth
                            variant="outlined"
                            name="price"
                            sx={{
                                width: '75px',
                                mr: 1
                            }}
                            onChange={(value) => handleChange(index, value)}
                            value={item.price}
                        />
                        <IconButton onClick={() => removeFields(index)}>
                            <Clear></Clear>
                        </IconButton>
                    </Box>
                ))
            }
            <Button sx={{
                my: 1
            }} fullWidth onClick={() => setProducts(list => [...list, { title: "", price: 0 }])}><Add /></Button>
            <TextField
                id="filled-multiline-static"
                label="Beskrivelse"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                onChange={(value) => setDescription(value.target.value)}
                defaultValue={description}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Annuller</Button>
            {
                item.id !== undefined ? <Button onClick={() => handleEdit(JSON.stringify({
                    id: item.id,
                    description: description,
                    orderitems: JSON.stringify(products)
                }))}>Færdig</Button> :
                    <Button onClick={validateAndSubmit}>Bestil</Button>}
        </DialogActions>
    </Dialog>
}