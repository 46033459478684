export const getUser = () => {
    try {
        let u = JSON.parse(window.localStorage.getItem("user"))
        return u;
    } catch (error) {
        return null
    }
}

export const setUser = (user) => {
    try {
        window.localStorage.setItem("user", JSON.stringify(user))
    } catch (error) {
        console.log(error)
    }
}