import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from '@mui/material';

export const AccountProfileDetails = ({ user, handleChange, onSubmit }) => {

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={onSubmit}
        >
            <Card>
                <CardHeader
                    subheader="Se og rediger dine informationer her"
                    title="Profil"
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Fornavn"
                                name="fname"
                                onChange={handleChange}
                                required
                                value={user.fname}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Efternavn"
                                name="lname"
                                onChange={handleChange}
                                required
                                value={user.lname}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Email Adresse"
                                name="email"
                                onChange={handleChange}
                                required
                                value={user.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Telefon"
                                name="phone"
                                onChange={handleChange}
                                type="number"
                                value={user.phone}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Gem oplysninger
                    </Button>
                </Box>
            </Card>
        </form>
    );
};