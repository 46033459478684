import { History, Person } from '@mui/icons-material';
import {
    Box,
    Button,
    Stack,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function FrontpageToolbar() {
    const navigate = useNavigate();

    return <Stack sx={{
        mb: 3,
    }} direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'flex-start', md: 'space-between' }}>
        <Typography
            sx={{ m: 1 }}
            variant="h3"
        >
            Bestillinger
        </Typography>
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ md: "center" }}>
            {/* <Button
                startIcon={(<Person fontSize="small" />)}
                onClick={() => navigate("/account")}
            >
                Profil
            </Button>
            <Button
                startIcon={(<History fontSize="small" />)}
                onClick={() => navigate("/history")}
            >
                historik
            </Button> */}
            <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/opret-ordre")}
            >
                Opret ny
            </Button>
        </Stack>
    </Stack>
};