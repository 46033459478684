import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { UserProvider } from './contexts/user/User';
import ThemeConfig from './theme';
import {
  // BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import PrivateRoute from './components/authentication/private-route';
import MainLayout from './layout/mainlayout';
import Account from './pages/account';
import CreateOrder from './pages/create-order';
import CreateUser from './pages/create-user';
import Frontpage from './pages/frontpage';
import History from './pages/history';
import Login from './pages/login';
import Order from './pages/order';
import SplashScreen from './pages/splashscreen';
import Router from './routes';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            light: '#9cd15b',
            main: '#84c633',
            dark: '#5c8a23',
            contrastText: '#fff',
          },
        },
      }),
    [prefersDarkMode],
  );
  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    <ThemeConfig>
      <UserProvider>
        {/* <Router>
        <Routes>
        <Route path="/" element={<PrivateRoute><MainLayout component={Frontpage}></MainLayout></PrivateRoute>} />
        <Route path="/splashscreen" element={<SplashScreen />} />
        <Route path="/login" element={<PrivateRoute login><Login /></PrivateRoute>} />
        <Route path="/create" element={<PrivateRoute login><CreateUser /></PrivateRoute>} />
        <Route path="/order/:id" element={<PrivateRoute><MainLayout component={Order}></MainLayout></PrivateRoute>} />
        <Route path="/history" element={<PrivateRoute><MainLayout component={History}></MainLayout></PrivateRoute>} />
        <Route path="/create-order" element={<PrivateRoute><MainLayout component={CreateOrder}></MainLayout></PrivateRoute>} />
        <Route path="/edit-order" element={<PrivateRoute><MainLayout component={CreateOrder}></MainLayout></PrivateRoute>} />
        <Route path="/account" element={<PrivateRoute><MainLayout component={Account}></MainLayout></PrivateRoute>} />
        </Routes>
      </Router> */}
        <Router />
      </UserProvider>
    </ThemeConfig>
    // </ThemeProvider>
  );
}

export default App;
