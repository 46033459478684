import { DateRange, Delete, Person, Timer, AttachMoney, PhoneIphone, Timelapse, Add, Settings, Edit, Remove, DeliveryDining } from "@mui/icons-material";
import { Button, Card, CardContent, CircularProgress, Container, Fab, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderDialog from "../components/order/order-dialog";
import OrderItem from "../components/order/order-item";
import { formatDateToText, formatTime } from "../functions/time-format";
import { getUser } from "../components/authentication/user";
import OrderInfoItem from "../components/order/order-info-item";
import Page from "../components/page";

export default function Order(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [order, setOrder] = useState(null);
    const [item, setItem] = useState([{}])
    const [openForOrder, setOpenForOrder] = useState(true)
    const [user, setUser] = useState({})
    const [width, setWidth] = useState(window.innerWidth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        getData();
    })

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        let u = getUser()
        setUser(u)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    const isMobile = width <= 768;

    const getData = () => {
        fetch("https://api.individualisterne.dk/api/order/" + id, {
            headers: {
                "token": window.localStorage.getItem("token")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    let now = new Date(Date.now())
                    let time = new Date(json.order.orderTime)
                    setOpenForOrder(now < time)
                    setOrder(json)
                })
            }
        })
    }


    const fabStyle = {
        position: 'fixed',
        right: 16,
        bottom: 16,
    };

    const onEditOrder = (order) => {
        setItem(order);
        setOpenDialog(true);
    }

    const handleDelete = (id) => {
        let confirm = window.confirm("Er du sikker på at du vil slette ordren?")
        if (confirm) {
            fetch("https://api.individualisterne.dk/api/orderitem/" + id, {
                method: 'DELETE',
                headers: {
                    "token": window.localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    getData();
                }
            })
        }
    }

    const handleCreateClick = () => {
        setAnchorEl(null);
        setItem({});
        setOpenDialog(true)
    }

    const handleEditClick = () => {
        setAnchorEl(null);
        navigate("/rediger-ordre", { state: order })
    }

    const handleTeamsNotificationClick = () => {
        setAnchorEl(null);
        fetch("https://api.individualisterne.dk/api/webhook", {
            method: 'POST',
            body: JSON.stringify(order),
            headers: {
                "Content-Type": "application/json",
                'token': window.localStorage.getItem("token")
            }
        })
    }

    const handleDeleteClick = () => {
        setAnchorEl(null);
        let confirm = window.confirm("Er du sikker på at du vil slette denne bestilling?")
        if (confirm) {
            fetch("https://api.individualisterne.dk/api/order/" + id, {
                method: 'DELETE',
                headers: {
                    "token": window.localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    navigate("/")
                }
            })
        }
    }


    const handleSubmit = (order) => {
        //This is where we submit the order
        fetch("https://api.individualisterne.dk/api/orderitem", {
            method: 'POST',
            body: order,
            headers: {
                "Content-Type": "application/json",
                "token": window.localStorage.getItem("token")
            }
        }).then(response => {
            if (response.ok) {
                setOpenDialog(false)
                getData();
            }
        })
    }
    const handleEdit = (order) => {
        //This is where we submit the order
        fetch("https://api.individualisterne.dk/api/orderitem", {
            method: 'PUT',
            body: order,
            headers: {
                "Content-Type": "application/json",
                "token": window.localStorage.getItem("token")
            }
        }).then(response => {
            if (response.ok) {
                setOpenDialog(false)
                getData();
            }
        })
    }

    const imageStyle = {
        height: 400,
        width: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        zIndex: -1
    }

    const imageStyleMobile = {
        ...imageStyle,
        height: 300
    }

    if (order === null) {
        return <CircularProgress></CircularProgress>
    }

    return <Page title={`${order.order.title} | ${formatDateToText(order.order.date)}`} image={order.order.image}>

        <Box
            component="main"
            sx={{
                flexGrow: 1,
                position: 'relative'
            }}>
            <img alt="order" style={isMobile ? imageStyleMobile : imageStyle} src={order.order.image}></img>
            <Box sx={isMobile ? { height: 250 } : { height: 350 }}></Box>
            <Container maxWidth={"lg"} sx={{ p: 0 }}>
                <Card sx={{
                    borderRadius: '10px',
                    position: 'relative',
                    p: 2
                }}>
                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap'
                        }}>
                            <Typography gutterBottom variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                {order.order.title}
                            </Typography>
                            <Button target={"_blank"} component="a" href={order.order.url} variant="contained">Se menu</Button>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            mt: 3
                        }}>
                            <OrderInfoItem icon={DateRange} title={"Bestillingsdato"} value={formatDateToText(order.order.date)}></OrderInfoItem>
                            <OrderInfoItem icon={Timer} title={"Bestilles til kl: "} value={formatTime(order.order.eatTime)}></OrderInfoItem>
                            <OrderInfoItem icon={Timelapse} title={"Bestilles inden kl: "} value={formatTime(order.order.orderTime)}></OrderInfoItem>
                            <OrderInfoItem icon={Person} title={"Bestillinger"} value={order.order.placedorders}></OrderInfoItem>
                            <OrderInfoItem icon={AttachMoney} title={"Udlægger"} value={order.order.name}></OrderInfoItem>
                            <OrderInfoItem icon={PhoneIphone} title={"MobilePay"} value={order.order.phone}></OrderInfoItem>
                            {
                                order.order.delivery === 0 ||
                                <OrderInfoItem icon={DeliveryDining} title={"Fragt"} value={`${order.order.delivery},- DKK`}></OrderInfoItem>
                            }
                        </Box>
                        <Typography sx={{ mt: 2 }} color="text.secondary" variant="body2">Se menuen for det valgte steds udvalg. Indtast derefter din ordre og benyt derefter MobilePay til at overføre til vedkommende som ligger ud</Typography>
                    </CardContent>
                </Card>
                {
                    !isMobile &&
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: 2
                    }}>
                        {
                            openForOrder ?
                                <Button variant="outlined" onClick={handleCreateClick}>Tilføj bestilling</Button> :
                                <Button variant="outlined" disabled>Bestilling er lukket</Button>
                        }
                        <Box sx={{ flexGrow: 1 }}></Box>
                        {
                            (user.id === order.order.userid || user.permission === "admin") &&
                            <Box>
                                <Button sx={{ mr: 2 }} onClick={handleTeamsNotificationClick} color="info" variant="outlined">Send Teams notifikation</Button>
                                <Button sx={{ mr: 2 }} onClick={handleEditClick} color="warning" variant="outlined">Rediger</Button>
                                <Button startIcon={<Delete></Delete>} variant="contained" onClick={handleDeleteClick} color="error">Slet</Button>
                            </Box>
                        }
                    </Box>
                }
                <Stack spacing={2}>
                    {
                        order.order_objects !== null ?
                            order.order_objects.map((o, index) => (
                                <OrderItem key={index} item={o}
                                    onEdit={onEditOrder} onDelete={handleDelete} user={user} openForOrder={openForOrder} isOwner={user.id === order.order.userid} isAdmin={user.permission === "admin"}></OrderItem>
                            )) : <Typography>Der er ikke oprettet nogle bestillinger endnu</Typography>
                    }
                </Stack>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    m: 2
                }}>
                    <Typography gutterBottom variant="h5" component="div">
                        Total Pris
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                        {order.order.total + ",-"}
                    </Typography>
                </Box>
                {
                    isMobile && (user.id === order.order.userid || user.permission === "admin") ?
                        <Fab color="primary" aria-label="add" sx={fabStyle} onClick={handleClick}>
                            <Settings />
                        </Fab> : isMobile && openForOrder &&
                        <Fab color="primary" aria-label="add" sx={fabStyle} onClick={handleCreateClick}>
                            <Add />
                        </Fab>
                }

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {
                        openForOrder ?
                            <MenuItem onClick={handleCreateClick}>
                                <ListItemIcon>
                                    <Add fontSize="small" color="success" />
                                </ListItemIcon>
                                <Typography variant="inherit">Tilføj bestilling</Typography>
                            </MenuItem> :
                            <MenuItem disabled onClick={handleCreateClick}>
                                <ListItemIcon>
                                    <Remove fontSize="small" color="success" />
                                </ListItemIcon>
                                <Typography variant="inherit">Lukket for bestilling</Typography>
                            </MenuItem>
                    }
                    <MenuItem onClick={handleTeamsNotificationClick}>
                        <ListItemIcon>
                            <Edit fontSize="small" color="info" />
                        </ListItemIcon>
                        <Typography variant="inherit">Send Teams notifikation</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleEditClick}>
                        <ListItemIcon>
                            <Edit fontSize="small" color="warning" />
                        </ListItemIcon>
                        <Typography variant="inherit">Rediger</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteClick}>
                        <ListItemIcon>
                            <Delete fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit">Slet</Typography>
                    </MenuItem>
                </Menu>
                <OrderDialog open={openDialog} onClose={() => setOpenDialog(false)} item={item} order_id={id} handleSubmit={handleSubmit} handleEdit={handleEdit} user={user}></OrderDialog>
            </Container >
        </Box >
    </Page>
}