import { Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function CreateOrderInfo(props) {
    const { info, setInfo, user, isMobile } = props;


    if (isMobile) {
        return <Box sx={{
            textAlign: 'center'
        }}>
        <Card sx={{
            borderRadius: '10px',
            position: 'relative',
            p: 2
        }}>
            <Typography variant="h5">Indtast oplysninger</Typography>
            <Typography variant="body2" color="text.secondary">Angiv hvem som ligger ud for bestillingen samt nummeret pengene skal overføres til via MobilePay</Typography>
        </Card>
            <Box sx={{
                my: 3,
                mx: 2,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column'
            }}>
                <TextField
                    margin="dense"
                    id="title"
                    label="Udlægger"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="title"
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        margin: 'auto',
                        my: 1,
                    }}
                    value={info.interpreter}
                    onChange={(value) => setInfo({ ...info, interpreter: value.target.value })}
                    defaultValue={user.fname + " " + user.lname}
                />
                <TextField
                    margin="dense"
                    id="title"
                    label="Mobil nummer"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="title"
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        margin: 'auto',
                        my: 1,
                    }}
                    value={info.phone}
                    defaultValue={user.phone}
                    onChange={(value) => setInfo({ ...info, phone: value.target.value })}
                />
                <TextField
                    margin="dense"
                    id="delivery"
                    label="Fragt"
                    type="number"
                    fullWidth
                    variant="outlined"
                    name="delivery"
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        margin: 'auto',
                        my: 1,
                    }}
                    value={info.phone}
                    defaultValue={user.phone}
                    onChange={(value) => setInfo({ ...info, phone: value.target.value })}
                />
            </Box>
        </Box>
    }

    return <Box sx={{
        textAlign: 'center'
    }}>
        <Typography variant="h5">Indtast oplysninger</Typography>
        <Box sx={{
            my: 3,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column'
        }}>
            <TextField
                margin="dense"
                id="title"
                label="Udlægger"
                type="text"
                fullWidth
                variant="outlined"
                name="title"
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    margin: 'auto',
                    my: 1,
                }}
                value={info.interpreter}
                onChange={(value) => setInfo({ ...info, interpreter: value.target.value })}
                defaultValue={user.fname + " " + user.lname}
            />
            <TextField
                margin="dense"
                id="title"
                label="Mobil nummer"
                type="text"
                fullWidth
                variant="outlined"
                name="title"
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    margin: 'auto',
                    my: 1,
                }}
                value={info.phone}
                defaultValue={user.phone}
                onChange={(value) => setInfo({ ...info, phone: value.target.value })}
            />
            <TextField
                margin="dense"
                id="delivery"
                label="Fragt"
                type="number"
                fullWidth
                variant="outlined"
                name="delivery"
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    margin: 'auto',
                    my: 1,
                }}
                value={info.delivery}
                defaultValue={user.delivery}
                onChange={(value) => setInfo({ ...info, delivery: value.target.value })}
            />
        </Box>
    </Box>
}