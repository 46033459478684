import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import FrontpageItem from '../components/frontpage/frontpage-item';
import { FrontpageToolbar } from '../components/frontpage/frontpage-toolbar';
import Page from '../components/page';

export default function Frontpage() {
    const [orders, setOrders] = useState([])
    const [auth, setAuth] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        fetch("https://api.individualisterne.dk/api/order/current", {
            method: 'GET',
            headers: {
                "token": window.localStorage.getItem("token"),
                "Content-Type": "application/json"
            },

        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    if (json !== null) {
                        setOrders(json)
                    }
                })
            } else if (response.status === 401) {
                setAuth(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }, [])

    if (!auth) {
        return <Navigate to="/login" />
    }

    return <Page title="Frokost | Individualisterne">
        <Container maxWidth={"lg"}>
            <FrontpageToolbar></FrontpageToolbar>
            <Grid container spacing={2}>
                {
                    orders.length > 0 ?
                        orders.map((order) => (
                            <Grid key={order.id} item xs={12} sm={6} xl={4} >
                                <FrontpageItem order={order} onClick={() => navigate("/bestilling/" + order.id)}></FrontpageItem>
                            </Grid>
                        )) : <Typography>Der er ingen bestillinger lige nu, men du kan oprette en</Typography>
                }
            </Grid>
        </Container>
    </Page>
}
