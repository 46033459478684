import { Button, Card, CardContent, Container, MobileStepper, Step, StepLabel, Stepper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrderMeta from "../components/create-order/create-order-meta";
import CreateOrderTime from "../components/create-order/create-order-time";
import CreateOrderInfo from "../components/create-order/create-order-info";
import CreateOrderConfirm from "../components/create-order/create-order-confirm";
import { getUser } from "../components/authentication/user";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Page from "../components/page";

const steps = ['Vælg sted', 'Vælg tidspunkt', 'Indtast oplysninger', 'Bekræft'];

export default function CreateOrder() {
    const [image, setImage] = useState(null)
    const [url, setUrl] = useState()
    const [title, setTitle] = useState()
    const [info, setInfo] = useState({})
    const [eatTime, setEatTime] = useState(new Date(Date.now()).setHours(12, 0, 0))
    const [orderTime, setOrderTime] = useState(new Date(Date.now()).setHours(10, 0, 0))
    const [date, setDate] = useState(new Date(Date.now()))
    const [activeStep, setActiveStep] = React.useState(0);
    const [user, setUser] = useState({})
    const navigate = useNavigate();
    const { state } = useLocation();
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        let u = getUser()
        setUser(u)
        setInfo({
            interpreter: u.fname + " " + u.lname,
            phone: u.phone,
            delivery: 0
        })
        if (state !== null) {
            setImage(state.order.image)
            setUrl(state.order.url)
            setTitle(state.order.title)
            setInfo({
                interpreter: state.order.name,
                phone: state.order.phone,
                delivery: state.order.delivery
            })
            setEatTime(state.order.eatTime)
            setOrderTime(state.order.orderTime)
            setDate(state.order.date)
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }
    }, [state])

    const isMobile = width <= 768;

    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFinish = () => {
        let d = new Date(date)
        let e = new Date(eatTime)
        let o = new Date(orderTime)
        if (state !== null) {
            fetch("https://api.individualisterne.dk/api/order", {
                method: 'PUT',
                body: JSON.stringify({
                    url: url,
                    title: title,
                    date: d.toISOString(),
                    eatTime: e.toISOString(),
                    orderTime: o.toISOString(),
                    name: info.interpreter,
                    phone: info.phone,
                    image: image,
                    id: state.order.id,
                    userid: user.id,
                    delivery: parseInt(info.delivery)
                }),
                headers: {
                    "Content-Type": "application/json",
                    "token": window.localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    navigate("/bestilling/" + state.order.id)
                }
            })
        }
        else {
            fetch("https://api.individualisterne.dk/api/order", {
                method: 'POST',
                body: JSON.stringify({
                    url: url,
                    title: title,
                    date: d.toISOString(),
                    eatTime: e.toISOString(),
                    orderTime: o.toISOString(),
                    name: info.interpreter,
                    phone: info.phone,
                    image: image,
                    userid: user.id,
                    delivery: parseInt(info.delivery)
                }),
                headers: {
                    "Content-Type": "application/json",
                    "token": window.localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    response.text().then(orderId => {
                        navigate("/order/" + orderId)
                    })
                }
            })
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleSetDate = (date) => {
        let d = new Date(date);
        let eat = new Date(eatTime);
        eat.setDate(d.getDate());
        eat.setMonth(d.getMonth());
        eat.setFullYear(d.getFullYear());
        let order = new Date(orderTime);
        order.setDate(d.getDate());
        order.setMonth(d.getMonth());
        order.setFullYear(d.getFullYear());
        setDate(d)
        setEatTime(eat)
        setOrderTime(order)
    }

    const getMetaInfo = (value) => {
        if (!value.target.value.includes("http")) {
            return
        }
        try {
            fetch("https://api.individualisterne.dk/api/link", {
                method: 'POST',
                body: JSON.stringify({
                    "link": value.target.value
                }),
                headers: {
                    "Content-Type": "application/json",
                    "token": window.localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        setImage(json.image)
                        setTitle(json.title)
                        setUrl(value.target.value)
                    })
                }
            }).catch(e => console.log(e))
        } catch (error) {
            console.log(error)
        }
    }

    const stepContent = [
        <CreateOrderMeta getMetaInfo={getMetaInfo} title={title} setTitle={setTitle} url={url} setUrl={setUrl} isMobile={isMobile}></CreateOrderMeta>,
        <CreateOrderTime time={eatTime} setTime={setEatTime} date={date} setDate={handleSetDate} orderTime={orderTime} setOrderTime={setOrderTime} isMobile={isMobile}></CreateOrderTime>,
        <CreateOrderInfo info={info} setInfo={setInfo} user={user} isMobile={isMobile}></CreateOrderInfo>,
        <CreateOrderConfirm information={{
            url: url,
            image: image,
            title: title,
            info: info,
            eatTime: eatTime,
            date: date,
            orderTime: orderTime
        }} isMobile={isMobile}></CreateOrderConfirm>
    ]


    const imageStyle = {
        height: 400,
        width: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        zIndex: -1
    }

    const imageStyleMobile = {
        ...imageStyle,
        height: 300
    }

    return <Page title="Opret ordre | Individualisterne">
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                position: 'relative'
            }}>
            <img alt="order" style={isMobile ? imageStyleMobile : imageStyle} src={image === null || !image.includes("http") ? "/images/kebab.jpg" : image}></img>
            <Box sx={isMobile ? { height: 250 } : { height: 350 }}></Box>
            <Container maxWidth={"lg"} sx={{ p: 0 }}>
                {!isMobile ? <Card sx={{
                    borderRadius: '10px',
                    position: 'relative',
                    p: 2
                }}>
                    <CardContent>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {
                            stepContent[activeStep]
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Forrige
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {
                                activeStep !== steps.length - 1 ?
                                    <Button onClick={handleNext}>
                                        Næste
                                    </Button> :
                                    <Button onClick={handleFinish}>
                                        Færdig
                                    </Button>
                            }

                        </Box>
                    </CardContent>
                </Card> : stepContent[activeStep]}
                {isMobile && <MobileStepper
                    variant="progress"
                    steps={stepContent.length}
                    position="bottom"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                        activeStep !== steps.length - 1 ? <Button size="small" onClick={handleNext}>
                            Næste
                            <KeyboardArrowRight />
                        </Button> : <Button size="small" onClick={handleFinish}>
                            Færdig
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>

                            <KeyboardArrowLeft />
                            Tilbage
                        </Button>
                    }
                />}
                {
                    !isMobile &&
                    <Box sx={{ my: 2 }}>
                        <Button onClick={() => navigate(-1)} color="error" variant="outlined">Afbryd</Button>
                    </Box>}
            </Container>
        </Box >
    </Page>
}