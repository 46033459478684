import { AdminPanelSettings, History, Home, Person, Settings } from '@mui/icons-material';

// ----------------------------------------------------------------------


const sidebarConfig = [
  {
    title: 'forside',
    path: '/',
    icon: <Home />
  },
  {
    title: 'historik',
    path: '/historik',
    icon: <History />
  },
  {
    title: 'profil',
    path: '/profil',
    icon: <Person />
  },
  // {
  //   title: 'indstillinger',
  //   path: '/indstillinger',
  //   icon: <Settings />
  // },
  {
    title: 'admin',
    path: '/admin',
    icon: <AdminPanelSettings />,
    admin: true,
    children: [
      {
        title: 'Brugere',
        path: '/admin/users'
      }
    ]
  },

];

export default sidebarConfig;
