import { ContentCopy, Delete, Edit, MoreVert } from "@mui/icons-material";
import { Card, CardContent, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

export default function OrderItem(props) {
    const { item, onEdit, onDelete, user, openForOrder, isOwner, isAdmin } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <Box sx={{ width: '100%' }}>
        <Card sx={{
            width: '100%',
            // maxWidth: '500px',
            // mr: 2,
            // mt: 2
        }} elevation={0}>
            <CardContent>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 1
                }}>
                    <Typography gutterBottom variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        {item.fname + " " + item.lname}
                    </Typography>
                    {
                        !openForOrder ||
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVert />
                        </IconButton>
                    }
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {
                            (user.id === item.user_id || isOwner || isAdmin) &&
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                onEdit(item)
                            }}>
                                <ListItemIcon>
                                    <Edit fontSize="small" color="warning" />
                                </ListItemIcon>
                                <Typography variant="inherit">Rediger</Typography>
                            </MenuItem>}
                        <MenuItem onClick={() => {
                            setAnchorEl(null);
                            onEdit({
                                orderitems: item.orderitems,
                                description: item.description
                            })
                        }}>
                            <ListItemIcon>
                                <ContentCopy fontSize="small" color="info" />
                            </ListItemIcon>
                            <Typography variant="inherit">Kopier ordre</Typography>
                        </MenuItem>
                        {
                            (user.id === item.user_id || isOwner || isAdmin) &&
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                onDelete(item.id)
                            }}>
                                <ListItemIcon>
                                    <Delete fontSize="small" color="error" />
                                </ListItemIcon>
                                <Typography variant="inherit">Slet</Typography>
                            </MenuItem>}
                    </Menu>
                </Box>
                {
                    item.orderitems !== null ?
                        JSON.parse(item.orderitems).map((prod, index) => {
                            return <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                                key={index}>
                                <Typography variant="body2">
                                    {prod.title}
                                </Typography>
                                <Typography variant="body2">
                                    {prod.price + ",-"}
                                </Typography>
                            </Box>
                        }) : <div></div>
                }
                {
                    item.delivery === 0 ||
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                    >
                        <Typography variant="body2">
                            Fragt
                        </Typography>
                        <Typography variant="body2">
                            {`${item.delivery},-`}
                        </Typography>
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography gutterBottom variant="h6" component="div">
                        Subtotal
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        {item.total + ",-"}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {item.description.String !== undefined ? item.description.String : item.description}
                </Typography>
            </CardContent>
        </Card>
    </Box>
}