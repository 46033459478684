import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import daLocale from "date-fns/locale/da";
import { Card, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function CreateOrderTime(props) {
    const { time, setTime, date, setDate, orderTime, setOrderTime, isMobile } = props;

    if (isMobile) {
        return <Box sx={{
            textAlign: 'center'
        }}>
            <Card sx={{
                borderRadius: '10px',
                position: 'relative',
                p: 2
            }}>
                <Typography variant="h5">Vælg tidspunkt</Typography>
                <Typography variant="body2" color="text.secondary">Vælg dato, tidspunkt ordren bestilles til samt seneste tidspunkt for at bestille</Typography>
            </Card>
            <Box sx={{
                my: 2,
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
                    <DatePicker
                        label="Dato"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        format="d MMM yyyy"
                        renderInput={(params) => <TextField {...params} />}

                    />
                </LocalizationProvider>
            </Box>
            <Box sx={{
                my: 2,
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label="Tidspunkt"
                        value={time}
                        onChange={(newValue) => {
                            setTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}

                    />
                </LocalizationProvider>
            </Box>
            <Box sx={{
                my: 2,
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label="Bestilles senest"
                        value={orderTime}
                        onChange={(newValue) => {
                            setOrderTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        ampm={false}

                    />
                </LocalizationProvider>
            </Box>
        </Box>
    }

    return <Box sx={{
        textAlign: 'center'
    }}>

        <Typography variant="h5">Vælg tidspunkt</Typography>
        <Box sx={{
            my: 2,
        }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
                <DatePicker
                    label="Dato"
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue);
                    }}
                    format="d MMM yyyy"
                    renderInput={(params) => <TextField {...params} />}

                />
            </LocalizationProvider>
        </Box>
        <Box sx={{
            my: 2,
        }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label="Tidspunkt"
                    value={time}
                    onChange={(newValue) => {
                        setTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}

                />
            </LocalizationProvider>
        </Box>
        <Box sx={{
            my: 2,
        }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label="Bestilles senest"
                    value={orderTime}
                    onChange={(newValue) => {
                        setOrderTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}

                />
            </LocalizationProvider>
        </Box>
    </Box>
}