import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccountProfile } from "../../components/account/account-profile";
import { AccountProfileAdminDetails } from "../../components/account/account-profile-admin-details";
import { AccountProfileDetails } from "../../components/account/account-profile-details";
import { AccountProfilePassword } from "../../components/account/account-profile-password";
import Page from "../../components/page";

export default function AdminEditUser() {
    const { state } = useLocation();
    const [updates, setUpdates] = useState({})

    useEffect(() => {
        setUpdates(state)
    }, [])

    const handleChange = (event) => {
        setUpdates(
            {
                ...updates,
                [event.target.name]: event.target.value
            }
        );
    };

    const onSubmit = (event) => {
        event.preventDefault();
        fetch("https://api.individualisterne.dk/api/admin/user", {
            method: 'PUT',
            body: JSON.stringify({ ...updates, id: state.id }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.ok) {
                window.alert("Bruger er blevet opdateret")
            }
        }).catch(e => console.log(e))

    }
    const onPasswordSubmit = (event) => {
        event.preventDefault();
        let body = {
            id: updates.id,
            password: updates.newPassword
        }
        if ((updates.newPassword.length > 0) && (updates.newPassword === updates.newPasswordConfirm)) {
            fetch("https://api.individualisterne.dk/api/user-password", {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.ok) {
                    window.alert("Aight mate!")
                    setUpdates(state)
                }
            }).catch(e => console.log(e))
        }
    }
    if (!state) {
        return <Page title="Rediger bruger">
            <Container>
                <CircularProgress />
            </Container>
        </Page>
    }
    return <Page title="Rediger bruger">
        <Container maxWidth="lg">
            <Typography
                variant="h3"
            >
                Rediger profil
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfile user={updates} />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfileAdminDetails user={updates} handleChange={handleChange} onSubmit={onSubmit} />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfilePassword user={updates} handleChange={handleChange} onSubmit={onPasswordSubmit} />
                </Grid>
            </Grid>
        </Container>
    </Page>
}