import React from 'react';
import { Navigate } from 'react-router-dom';
import { setUser } from './user';

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export default function PrivateAdminRoute({ children, login }) {
    try {
        let token = parseJwt(window.localStorage.getItem("token"))
        setUser(token)
        if (token.permission === 'admin') {
            return children;
        }
        return <Navigate to="/" />
    } catch (error) {
        if (login) {
            return children
        } else {
            return <Navigate to="/login" />
        }
    }
}