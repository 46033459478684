import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function AccountProfile(props) {
    const { user } = props;
    const navigate = useNavigate();
    return <Box
        sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        {/* <Avatar
                    sx={{
                        height: 64,
                        mb: 2,
                        width: 64,
                        bgcolor: 'primary.main'
                    }}
                >
                    {user.fname.charAt(0).toUpperCase() + user.lname.charAt(0).toUpperCase()}
                </Avatar> */}
        <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
        >
            {user.fname + " " + user.lname}
        </Typography>
        <Typography
            color="textSecondary"
            variant="body2"
        >
            Individualisterne
        </Typography>
        <Typography
            color="textSecondary"
            variant="body2"
        >
            {user.timezone}
        </Typography>
    </Box>
};