const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}


const addZero = (num) => {
    if (num < 10) {
        return "0" + num;
    }
    return num;
}

export const formatDate = (date) => {
    let d = new Date(date)
    return addZero(d.getDate()) + "/" + addZero(d.getMonth() + 1);
}

export const formatDateToText = (date) => {
    let d = new Date(date)
    if (isToday(d)) {
        return "Idag"
    } else {
        return addZero(d.getDate()) + "/" + addZero(d.getMonth() + 1);
    }
}


export const formatTime = (time) => {
    let t = new Date(time);
    return addZero(t.getHours()) + ":" + addZero(t.getMinutes())
}