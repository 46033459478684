import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AccountProfile } from '../components/account/account-profile';
import { AccountProfileDetails } from '../components/account/account-profile-details';
import { AccountProfilePassword } from '../components/account/account-profile-password';
import { getUser } from '../components/authentication/user';
import Page from '../components/page';
import { UserContext } from '../contexts/user/User';

function Account() {
    const [user, dispatch] = useContext(UserContext)
    const [updates, setUpdates] = useState({})

    useEffect(() => {
        setUpdates(user)
    }, [])

    const handleChange = (event) => {
        setUpdates(
            {
                ...updates,
                [event.target.name]: event.target.value
            }
        );
    };

    const onSubmit = (event) => {
        event.preventDefault();
        fetch("https://api.individualisterne.dk/api/user", {
            method: 'PUT',
            body: JSON.stringify({ ...updates, id: user.id }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    window.localStorage.setItem("token", json.token);
                    window.localStorage.setItem("user", JSON.stringify(json.user));
                    dispatch({
                        type: 'set',
                        user: json.user
                    })
                    setUpdates(json.user)
                })
            }
        }).catch(e => console.log(e))

    }

    const onPasswordSubmit = (event) => {
        event.preventDefault();
        let body = {
            id: updates.id,
            password: updates.newPassword
        }
        console.log(body)
        if ((updates.newPassword.length > 0) && (updates.newPassword === updates.newPasswordConfirm)) {
            fetch("https://api.individualisterne.dk/api/user-password", {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.ok) {
                    window.alert("Aight mate!")
                    setUpdates(user)
                }
            }).catch(e => console.log(e))
        }
    }

    if (user === null) {
        return <Box sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress></CircularProgress>
        </Box>
    }
    return <Page title={`${user.fname} | Individualisterne`}>
        <Container maxWidth="lg">
            <Typography
                variant="h3"
            >
                Profil
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfile user={updates} />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfileDetails user={updates} handleChange={handleChange} onSubmit={onSubmit} />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Box />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <AccountProfilePassword user={user} handleChange={handleChange} onSubmit={onPasswordSubmit} />
                </Grid>
            </Grid>
        </Container>
    </Page>
};

export default Account;