import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/system";
import { DateRange, Person, Timer } from "@mui/icons-material";
import { formatDateToText, formatTime } from "../../functions/time-format";

export default function FrontpageItem({ onClick, order }) {
    const openForOrder = new Date(Date.now()) < new Date(order.orderTime)

    return <Card sx={{
        // maxWidth: 350,
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
            opacity: .7
        },
    }} onClick={onClick}>
        <CardMedia
            component="img"
            height="140"
            image={order.image}
            alt={order.title}
        />
        <CardContent>
            {
                //This is the title
            }
            <Typography gutterBottom variant="h5" component="div" sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {order.title}
            </Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <DateRange sx={{
                        mr: 1
                    }}></DateRange>
                    <Typography variant="body2">
                        {formatDateToText(order.date)}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Timer sx={{
                        mr: 1
                    }}></Timer>
                    <Typography variant="body2">
                        {formatTime(order.eatTime)}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Person sx={{
                        mr: 1
                    }}></Person>
                    <Typography variant="body2">
                        {order.placedorders}
                    </Typography>
                </Box>
            </Box>
            <Typography sx={{
                mt: 1
            }}
                color={openForOrder ? "success" : "error"}>
                {openForOrder ? "Bestilling lukker kl " + formatTime(order.orderTime) : "Bestilling er lukket"}
            </Typography>
        </CardContent>
    </Card>
}