import { Avatar, Card, Checkbox, Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { filter } from 'lodash';
import Page from "../../components/page";
import UserListHead from '../../components/admin/user/UserListHead'
import Scrollbar from "../../components/Scrollbar";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
    { id: 'fname', label: 'Fornavn', alignRight: false },
    { id: 'lname', label: 'Efternavn', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Telefon', alignRight: false },
    { id: 'permission', label: 'Rettigheder', alignRight: true },
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) => _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}
export default function AdminUsers() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [users, setUsers] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        fetch("https://api.individualisterne.dk/api/user", {
            headers: {
                "token": window.localStorage.getItem("token")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    setUsers(json)
                    setLoading(false)
                })
            }
        })
    }, [])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

    return <Page title="Administrer brugere">
        <Container sx={{ height: 'min-content' }}>
            {/* <Stack> */}
            <Typography variant="h3">Brugere</Typography>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={users.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {
                                    loading ?
                                        [...Array(rowsPerPage)].map(() => (

                                            <TableRow>
                                                <TableCell align="left"><Skeleton variant="text" /></TableCell>
                                                <TableCell align="left"><Skeleton variant="text" /></TableCell>
                                                <TableCell align="left"><Skeleton variant="text" /></TableCell>
                                                <TableCell align="left"><Skeleton variant="text" /></TableCell>
                                                <TableCell align="right"><Skeleton variant="text" /></TableCell>
                                            </TableRow>
                                        )) :
                                        filteredUsers
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const isItemSelected = selected.indexOf(row.id) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        sx={{
                                                            '& > *:hover': {
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        onClick={() => navigate("/admin/edit-user", { state: row })}
                                                    >

                                                        <TableCell align="left">{row.fname}</TableCell>
                                                        <TableCell align="left">{row.lname}</TableCell>
                                                        <TableCell align="left">{row.email}</TableCell>
                                                        <TableCell align="left">{row.phone}</TableCell>
                                                        <TableCell align="right">{row.permission}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                {(emptyRows > 0 && !loading) && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={5} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rækker pr side"
                />
                {/* </Stack> */}
            </Card>
        </Container>
    </Page>
}