import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function OrderInfoItem({ icon: Icon, title, value, ...props }) {
    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        mt: 1,
    }}>
        {/* <Box> */}
        <Icon sx={{
            mr: 1,
            color: 'text.secondary'
        }}></Icon>
        {/* </Box> */}
        <Box sx={{ width: 150 }}>
            <Typography color="text.secondary" variant="body2">
                {title}
            </Typography>
        </Box>
        <Box sx={{ textAlign: 'start' }}>
            <Typography variant="body2">
                {value}
            </Typography>
        </Box>
    </Box>
}