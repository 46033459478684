import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import jwtDecode from 'jwt-decode';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import Page from '../components/page';
import { UserContext } from '../contexts/user/User';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://individualisterne.dk/">
                Individualisterne
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function Login(props) {
    // const { setUser } = props;
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [user, dispatch] = useContext(UserContext);
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch("https://api.individualisterne.dk/api/login", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                "email": data.get('username'),
                "password": data.get('password')
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json().then(json => {
            if (response.ok) {
                window.localStorage.setItem("token", json.token)
                // window.localStorage.setItem("user", JSON.stringify(json))
                // setUser(json)
                dispatch({ type: "set", user: jwtDecode(json.token) })
                navigate("/")
            } else {
                setErrorMessage("Forkert email eller adgangskode")
                setError(true)
            }
        })).catch(e => {
            setErrorMessage("Forkert email eller adgangskode")
            setError(true)
        })
    };

    return (
        <Page title="Login | Individualisterne">

            <Box sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img alt="logo" src="images/logostu.png" style={{ width: '100%' }}></img>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Email"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                error={error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Adgangskode"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={error}
                                helperText={error ? errorMessage : null}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Log ind
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}>
                                        Glemt adgangskode?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link onClick={() => navigate("/opret")} variant="body2" sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}>
                                        {"Har du ikke en konto? Tilmeld dig"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </Box>
        </Page>
    );
}