import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../../components/page";

export default function NotFound() {
    return <Page>
        <Container>
            <Stack height='100%' alignItems="center">
                <Typography variant="h1">404</Typography>
                <img src="images/sad.png" style={{ width: '80%' }} />
                <Typography>Siden findes ikke</Typography>
            </Stack>
        </Container>
    </Page>
}