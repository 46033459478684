import { Card, CardContent, CardMedia, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../components/authentication/user";
import Page from "../components/page";
import { formatDateToText } from "../functions/time-format";

export default function History() {
    const [orders, setOrders] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        let u = getUser();
        fetchOrders(u)
    }, [])

    const fetchOrders = (user) => {
        fetch("https://api.individualisterne.dk/api/order/customer/" + user.id, {
            headers: {
                "token": window.localStorage.getItem("token")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(json => setOrders(json))
            }
        }).catch(e => console.log(e))
    }

    return <Page title="Historik | Individualisterne">

        <Container maxWidth="lg">
            <Box sx={{
                mb: 3,
            }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1
                    }}
                >
                    <Typography
                        variant="h3"
                    >
                        Historik
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {
                    orders.map((item, index) => (

                        <Grid key={index} item xs={12} md={4}>
                            <Card sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    opacity: .7
                                },
                            }} onClick={() => navigate("/bestilling/" + item.order_id)}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={item.image}
                                    alt={item.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {item.title}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" color="text.secondary" component="div">
                                        {formatDateToText(item.date)}
                                    </Typography>
                                    {
                                        JSON.parse(item.orderitems).map((order, idx) => (
                                            <Stack key={idx} direction="row" alignItems="center" justifyContent="space-between">
                                                <Typography>{order.title}</Typography>
                                                <Typography>{`${order.price},- DKK`}</Typography>
                                            </Stack>
                                        ))
                                    }
                                    {
                                        item.delivery === 0 ||
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Typography>Fragt</Typography>
                                            <Typography>{`${item.delivery},- DKK`}</Typography>
                                        </Stack>
                                    }
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h5">Total</Typography>
                                        <Typography variant="h5">{`${item.total},- DKK`}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    </Page>
}