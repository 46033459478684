import { Navigate, useRoutes } from "react-router-dom";
import PrivateAdminRoute from "./components/authentication/private-admin-route";
import PrivateRoute from "./components/authentication/private-route";
import MainLayout from "./layout/main";
import Account from "./pages/account";
import AdminEditUser from "./pages/admin/EditUser";
import AdminUsers from "./pages/admin/Users";
import CreateOrder from "./pages/create-order";
import CreateUser from "./pages/create-user";
import Frontpage from "./pages/frontpage";
import History from "./pages/history";
import Login from "./pages/login";
import Order from "./pages/order";
import NotFound from "./pages/static/NotFound";

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                {
                    path: '/',
                    element: <Frontpage />
                },
                {
                    path: '/historik',
                    element: <History />
                },
                {
                    path: '/opret-ordre',
                    element: <CreateOrder />
                },
                {
                    path: '/rediger-ordre',
                    element: <CreateOrder />
                },
                {
                    path: '/bestilling/:id',
                    element: <Order />
                },
                {
                    path: '/profil',
                    element: <Account />
                },
                {
                    path: '/404',
                    element: <NotFound />
                },
                {
                    path: '*',
                    element: <Navigate to="/404" />
                },
            ]
        },
        {
            path: '/admin',
            element: (
                <PrivateAdminRoute>
                    <MainLayout />
                </PrivateAdminRoute>
            ),
            children: [
                {
                    path: '/admin/users',
                    element: <AdminUsers />
                },
                {
                    path: '/admin/edit-user',
                    element: <AdminEditUser />
                },
            ]
        },
        {
            path: '/login',
            element: (
                <Login />
            )
        },
        {
            path: '/opret',
            element: (
                <CreateUser />
            )
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ])
}